import api from '../lib/api';

const farmerSources = {
  loginFarmers: async (data) => {
    const response = await api.post('/open-store-request', data);

    return response.data;
  },
};

export default farmerSources;
