import React, { useState } from 'react';
import axios from 'axios';
// import { debounce } from 'lodash';
import { AsyncSelect } from '../FormControls';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';

function fetchAddress(value, params = {}) {
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
  const token = process.env.DADATA_API_TOKEN;
  const query = `город Москва ${value}`;

  return axios({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + token,
    },
    data: JSON.stringify({ query: query, ...params }),
  });
}

const GeoAddress = (props) => {
  const { selectAddress, value: defaultValue, restrictStreet = false, isMobile } = props;
  const [value, setValue] = useState(defaultValue);
  const queryParams = restrictStreet
    ? {
        from_bound: { value: 'street' },
        to_bound: { value: 'street' },
      }
    : {};

  const loadOptions = async (value, callback) => {
    const response = await fetchAddress(value, queryParams);

    const suggestions = response.data.suggestions.map((item) => ({
      id: item.value,
      name: item.value,
    }));
    callback(suggestions);
  };

  const handleChange = async (option) => {
    const response = await fetchAddress(option.id, {
      ...queryParams,
      count: 1,
    });

    const item = response.data.suggestions[0];

    const selectedOption = {
      id: item.value,
      name: item.value,
      data: {
        cityDistrict: item.data.city_district || item.data.federal_district,
      },
    };
    selectAddress(selectedOption);
    setValue(selectedOption);
  };

  const isFocus = () => {
    Emmiter.emit(EVENTS.FOCUS_SELECT);
  };

  return (
    <AsyncSelect
      menuPlacement={isMobile ? 'top' : 'auto'}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      placeholder="Адрес"
      loadOptions={loadOptions}
      onChange={handleChange}
      value={value}
      onFocus={isFocus}
    />
  );
};

export default GeoAddress;
