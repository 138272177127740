import React, { useState, useEffect } from 'react';
import styles from './FarmerRegistration.module.scss';
import Button from '../../Button';
import Input from '../../Input/Input';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import { Form, Field } from 'react-final-form';
import InputMask from '../../FormControls/InputMask';
import farmerSources from '../../../sources/farmerSources';
import { phoneValidation, required } from '../../../helpers/validations';
import useBodyOverflow from '../../../hooks/useBodyOverflow';

import { NotificationManager } from 'react-notifications';
import Textarea from '../../FormControls/Textarea/Textarea';

const initialValues =
  process.env.NODE_ENV === 'development'
    ? {
        hasCertificate: true,
        storeName: 'Дмитрий Александров',
        description: `Ферма Федоровская сильно отличается от подобных себе:
            часть кур, около 15 000 голов, содержится на свободном выгуле.`,
        address: 'Бардина 6 Москва',

        volumeProductsInWeek: 'Объем',
        name: 'Дмитрий Александров',
        phone: '+7999999',
        email: 'test+3@mail.ru',
        additionalInfo: 'Дополнительная информация',
      }
    : {};

const FarmerRegistration = () => {
  const [isShowFarmer, setIsShowFarmer] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const onChangePrivacyPolicy = () => {
    setIsChecked((prev) => !prev);
  };
  useEffect(() => {
    const handleEvent = () => {
      setIsShowFarmer(true);
    };

    Emmiter.on(EVENTS.OPEN_MODAL_FARMER_REGISTRATION, handleEvent);

    return () => {
      Emmiter.off(EVENTS.OPEN_MODAL_FARMER_REGISTRATION, handleEvent);
    };
  }, []);

  const closeWindow = () => {
    setIsShowFarmer(false);
  };

  useBodyOverflow(isShowFarmer);

  const sendInfo = async (values) => {
    try {
      await farmerSources.loginFarmers({
        storeName: values.storeName,
        description: values.description,
        address: values.address,
        products: values.products,
        volumeProductsInWeek: values.volumeProductsInWeek,
        hasCertificate: values.hasCertificate || false,
        name: values.name,
        phone: values.phone,
        email: values.email,
        additionalInfo: values.additionalInfo,
      });

      NotificationManager.success('Данные успешно отправлены');
      closeWindow();
    } catch (error) {
      NotificationManager.error('Ошибка при отправке данных');
      console.error(error);
    }
  };

  if (!isShowFarmer) return null;

  return (
    <>
      <div className={styles.popupMenu}></div>
      {isShowFarmer && (
        <div className={styles.modalWrapper} onClick={closeWindow}>
          <div className={styles.modal} onClick={(event) => event.stopPropagation()}>
            <div className={styles.header}>
              <div className={styles.headerTitleBlock}>
                <div className={styles.headerTitle}>Анкета поставщика</div>
                <div className={styles.headerSubtitle}>
                  Поля помеченные звездочкой (*) являются обязательными
                </div>
              </div>
              <Button
                className={styles.headerCloseButton}
                variant="transparent"
                onClick={closeWindow}
              >
                <img about="close" src=" /icons2/close-farmer_registration.svg"></img>
              </Button>
            </div>
            <Form
              onSubmit={sendInfo}
              initialValues={initialValues}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.farmInformationContainer}>
                    <div className={styles.farmInformationTitle}>Информация о хозяйстве</div>
                    <div className={styles.farmInformationInputBlock}>
                      <Field
                        validate={required()}
                        name="storeName"
                        render={({ input, meta }) => (
                          <Input meta={meta} {...input} placeholder="Название хозяйства. *"></Input>
                        )}
                      />

                      <div className={styles.aboutFarm}>
                        {' '}
                        <Field
                          validate={required()}
                          name="description"
                          render={({ input, meta }) => (
                            <Textarea
                              meta={meta}
                              {...input}
                              placeholder="Несколько слов о вашей ферме/хозяйстве. *"
                              rows="2"
                            ></Textarea>
                          )}
                        />
                      </div>
                      <div className={styles.adressFarm}>
                        <Field
                          validate={required()}
                          name="address"
                          render={({ input, meta }) => (
                            <Input meta={meta} {...input} placeholder="Адрес хозяйства. *"></Input>
                          )}
                        />
                      </div>
                      <div className={styles.productsFarm}>
                        {' '}
                        <Field
                          validate={required()}
                          name="products"
                          render={({ input }) => (
                            <Textarea
                              {...input}
                              className={styles.productsFarmInput}
                              placeholder="Какие продукты будете размещать (через запятую). Например: молочная продукция, мясо. *"
                              rows="5"
                              lgPadding
                            ></Textarea>
                          )}
                        />
                      </div>

                      <div className={styles.productsVolumeFarm}>
                        <Field
                          validate={required()}
                          name="volumeProductsInWeek"
                          render={({ input, meta }) => (
                            <Textarea
                              meta={meta}
                              {...input}
                              placeholder="Текущий объем в неделю по ключевым позициям.*"
                              className={styles.productsVolumeFarmInput}
                              rows="2"
                            ></Textarea>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.availabilityCertification}>
                    <div className={styles.availabilityCertificationTitle}>
                      Наличие сертификации *{' '}
                    </div>
                    <Field
                      name="hasCertificate"
                      render={({ input }) => (
                        <div>
                          <div className={styles.availabilityCertificationYes}>
                            <input
                              type="checkbox"
                              id="myCheckbox"
                              className={styles.customCheckbox}
                              checked={input.value}
                              onChange={() => input.onChange(!input.value)}
                            />
                            <label for="myCheckbox">Да</label>
                          </div>
                          <div className={styles.availabilityCertificationNo}>
                            <input
                              type="checkbox"
                              id="myCheckbox"
                              className={styles.customCheckbox}
                              checked={!input.value}
                              onChange={() => input.onChange(!input.value)}
                            />
                            <label for="myCheckbox">Нет</label>
                          </div>
                        </div>
                      )}
                    />
                  </div>

                  <div className={styles.contactInformationBlock}>
                    <div className={styles.contactInformationTitle}>Контактная информация</div>
                    <div className={styles.contactInformationInputs}>
                      <Field
                        validate={required()}
                        name="name"
                        render={({ input, meta }) => (
                          <Input meta={meta} {...input} placeholder="Имя *"></Input>
                        )}
                      />
                      <div className={styles.contactInformationPhone}>
                        <Field
                          name="phone"
                          validate={phoneValidation}
                          render={({ input, meta }) => (
                            <InputMask label="Телефон *" meta={meta} {...input}></InputMask>
                          )}
                        />
                      </div>
                      <div className={styles.contactInformationMail}>
                        <Field
                          name="email"
                          render={({ input, meta }) => (
                            <Input meta={meta} {...input} placeholder="Почта *"></Input>
                          )}
                        />
                      </div>
                      <div className={styles.informationFarmer}>
                        {' '}
                        <Field
                          name="additionalInfo"
                          render={({ input, meta }) => (
                            <Textarea
                              meta={meta}
                              className={styles.informationFarmerInput}
                              {...input}
                              placeholder={
                                'Что еще, по вашему мнению, нам будет полезно узнать о вашем хозяйстве?'
                              }
                              lgPadding
                              rows="3"
                            ></Textarea>
                          )}
                        />
                      </div>
                    </div>
                    <Field name="agreement" type="checkbox">
                      {({ input }) => (
                        <div className={styles.contactInformationCheckbox}>
                          <input
                            type="checkbox"
                            id="privacyPolicy"
                            className={styles.customCheckboxPrivacyPolicy}
                            onChange={onChangePrivacyPolicy}
                            value={input.value}
                            checked={isChecked}
                          />
                          <label for="privacyPolicy">
                            Согласен(-на) с офертой и политикой конфиденциальности
                          </label>
                        </div>
                      )}
                    </Field>
                  </div>
                  <Button
                    className={styles.furtherButton}
                    variant="transparent"
                    type="submit"
                    disabled={!isChecked || submitting}
                  >
                    Отправить
                  </Button>
                </form>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FarmerRegistration;
