import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { required } from '../../helpers/validations';
import VerificationInput from '../FormControls/VerificationInput';
import styles from './Authorization.module.scss';

const initialTimer = { minutes: 1, seconds: 0 };

const AuthorizationSmsCode = () => {
  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    const myInterval = setInterval(() => {
      const { seconds, minutes } = timer;

      if (seconds > 0) {
        setTimer({
          minutes,
          seconds: seconds - 1,
        });
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setTimer({
            minutes: minutes - 1,
            seconds: 59,
          });
        }
      }
    }, 1000);
    return () => clearInterval(myInterval);
  }, [timer]);

  const resendCode = () => {

  }

  const requestResend = () => {
    resendCode();
    setTimer(initialTimer);
  };

  return (
    <div className={styles.authorizationInputWrapper}>
      <Field name="confirmCode" validate={required()}>
        {({ input, meta }) => (
          <VerificationInput
            className={{
              [styles.authorizationEnterCodeInput]: true,
            }}
            {...input}
            meta={meta}
          />
        )}
      </Field>
      <div className={styles.authorizationSendAgainContainer}>
        {timer.minutes > 0 || timer.seconds > 0 ? (
          <>
            <div className={styles.authorizationSendAgain__title}>Отправить код снова </div>
            <div className={styles.authorizationSendAgain__time}>
              ({timer.minutes}:{timer.seconds})
            </div>
          </>
        ) : (
          <>
            <button className={styles.resendCode} type="button" onClick={requestResend}>
              Отправить код снова
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthorizationSmsCode;
