export const PRODUCT_UNITS = {
  PIECE: 1,
  KILOGRAM: 2,
  GRAM: 3,
  MILLILITERS: 4,
  LITER: 5,
  PIECE_GR: 6,
};

export const PRODUCT_UNITS_OPTIONS = [
  { id: PRODUCT_UNITS.PIECE, name: 'шт' },
  { id: PRODUCT_UNITS.KILOGRAM, name: 'кг' },
  { id: PRODUCT_UNITS.GRAM, name: 'гр' },
  { id: PRODUCT_UNITS.MILLILITERS, name: 'мл' },
  { id: PRODUCT_UNITS.LITER, name: 'л' },
];

export const PRODUCT_UNITS_NAMES = {
  [PRODUCT_UNITS.PIECE]: 'шт',
  [PRODUCT_UNITS.KILOGRAM]: 'кг',
  [PRODUCT_UNITS.GRAM]: 'гр',
  [PRODUCT_UNITS.MILLILITERS]: 'мл',
  [PRODUCT_UNITS.LITER]: 'л',
};

export const PRODUCT_SIZE_TYPES_NAMES = { 1: 'Gr', 2: 'Ml' };

export const PRODUCT_CONTAINER_TYPES = {
  BOX: 1,
  CONTAINER: 2,
};
export const PRODUCT_CONTAINER_TYPES_OPTIONS = [
  { id: 1, name: 'Коробка' },
  { id: 2, name: 'Контейнер' },
];
