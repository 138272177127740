import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { ru } from 'date-fns/locale';
import getDayOfYear from 'date-fns/getDayOfYear';

const DAY = {
  0: 'deliverySunday',
  1: 'deliveryMonday',
  2: 'deliveryTuesday',
  3: 'deliveryWednesday',
  4: 'deliveryThursday',
  5: 'deliveryFriday',
  6: 'deliverySaturday',
};

export const getDate = (date) => format(date, 'dd MMMM', { locale: ru });

export const getTime = (date) => format(date, 'HH:mm', { locale: ru });

export const getWeekDaysArray = () => {
  const startDate = addDays(new Date(), 1);
  const endDate = addDays(startDate, 6);

  const datesArray = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const formattedDate = format(currentDate, 'd MMMM, EEEEEE', { locale: ru });
    const dayOfYear = getDayOfYear(currentDate);

    datesArray.push({
      id: dayOfYear,
      name: formattedDate,
      data: DAY[currentDate.getDay()],
    });

    currentDate = addDays(currentDate, 1);
  }

  return datesArray;
};
