import React, { useState, useEffect, useRef } from 'react';
import { YMaps, Map as YMap, ZoomControl } from 'react-yandex-maps';
import head from 'lodash/head';
import styles from './GEO.module.scss';
import Emmiter from '../../lib/emmiter';
import Button from '../Button';
import cn from 'classnames';
import { setCookie } from '../../lib/cookie';
import { COOKIE } from '../../constants/COOKIE';
import GeoAddress from './GeoAddress';
import Input from '../Input';
import Spinner from '../Spinner';
import districtsSources from '../../sources/districtsSources';
import { NotificationManager } from 'react-notifications';
import EVENTS from '../../constants/EVENTS';
import useBodyOverflow from '../../hooks/useBodyOverflow';

const GEO = () => {
  const [isShowGEO, setIsShowGEO] = useState(false);
  const [isFocus, setIsFocus] = useState(true);

  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window.innerWidth < 768,
  );
  const [state, setState] = useState({
    mapApi: null,
    isLoading: true,
    selectedAddress: null,
    mapCoordinates: [55.75, 37.57],
  });

  const mapRef = useRef();

  const { mapCoordinates, isLoading } = state;

  const saveDeliveryAddress = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const deliveryDistrict = await districtsSources.findByName(
        state.selectedAddress.data.cityDistrict,
      );
      const district = head(deliveryDistrict);

      setCookie(COOKIE.DELIVERY_DISTRICT, JSON.stringify(district));
      setCookie(COOKIE.DELIVERY_DISTRICT_ID, district.id);
      setCookie(COOKIE.DELIVERY_ADDRESS_NAME, state.selectedAddress.name);
      window.location.reload();
    } catch (error) {
      NotificationManager.error('Ошибка. Обратитесь в службу поддержки.');
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const notInputFocus = () => {
    setIsFocus(false);
  };

  const closeGEOHandleClick = () => {
    sessionStorage.setItem('closeGeo', 'true');
    setIsShowGEO(false);
  };

  const setMapAddress = (address, mapApi = state.mapApi) => {
    mapApi.geocode(address, { results: 1 }).then((response) => {
      const firstGeoObject = response.geoObjects.get(0);
      firstGeoObject.options.set('preset', 'islands#darkBlueDotIconWithCaption');
      firstGeoObject.properties.set('iconCaption', firstGeoObject.getAddressLine());
      const coords = firstGeoObject.geometry.getCoordinates();
      const bounds = firstGeoObject.properties.get('boundedBy');

      if (mapRef.current) {
        mapRef.current.geoObjects.removeAll();
        mapRef.current.geoObjects.add(firstGeoObject);
        mapRef.current.setBounds(bounds, {
          checkZoomRange: true,
        });
      }

      setState((prevState) => ({
        ...prevState,
        mapApi: prevState.mapApi || mapApi,
        isLoading: false,
        mapCoordinates: coords,
      }));
    });
  };

  const selectAddress = (address) => {
    setState((prevState) => ({
      ...prevState,
      selectedAddress: address,
    }));
    setMapAddress(address.name);
  };

  const handleLoadMap = (mapApi) => {
    setMapAddress('Москва', mapApi);
  };

  useEffect(() => {
    const handleEvent = () => {
      setIsShowGEO(true);
    };

    Emmiter.on(EVENTS.OPEN_GEO_MODAL, handleEvent);

    return () => {
      Emmiter.off(EVENTS.OPEN_GEO_MODAL, handleEvent);
    };
  }, []);

  useBodyOverflow(isShowGEO);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isFocus]);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocus(true);
    };

    Emmiter.on(EVENTS.FOCUS_SELECT, handleFocus);

    return () => {
      Emmiter.off(EVENTS.FOCUS_SELECT, handleFocus);
    };
  }, []);

  if (!isShowGEO) return null;

  return (
    <>
      <div className={styles.popupMenu} onClick={closeGEOHandleClick}></div>
      <div className={styles.wrapper}>
        <div className={styles.geoContainer}>
          <div>
            <div className={styles.titleContainer}>
              <div className={styles.title}>АДРЕС ДОСТАВКИ</div>
              <Button className={styles.close} variant="transparent" onClick={closeGEOHandleClick}>
                <img alt="exit" src="/icons2/crossed large, close.svg"></img>
              </Button>
            </div>
            {isFocus ? null : (
              <div>
                <div className={styles.subTitle}>
                  Повлияет на ассортимент, цены и условия получения заказа
                </div>
              </div>
            )}
          </div>

          <YMaps query={{ apikey: process.env.YANDEX_MAP_API_TOKEN, lang: 'ru_RU' }}>
            <div className={styles.map}>
              <YMap
                defaultState={{ center: mapCoordinates, zoom: 9 }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                modules={['geocode']}
                onLoad={handleLoadMap}
                state={{ center: mapCoordinates, zoom: 15 }}
                instanceRef={(ref) => {
                  if (ref) ref.behaviors.disable('scrollZoom');
                  mapRef.current = ref;
                }}
              >
                <ZoomControl />
              </YMap>
            </div>
          </YMaps>

          <div className={styles.inputContainer}>
            <div className={styles.selectAddress}>
              <Input placeholder="Город" value="Москва" disabled={true} />
            </div>
            <div className={styles.searchAddress}>
              <GeoAddress selectAddress={selectAddress} isFocus={isFocus} isMobile={isMobile} />
            </div>

            {/* {inputValue && (
            <Button
              className={cn(styles.deleteButton, { [styles.deleteButtonMap]: isFocus })}
              variant="transparent"
              onClick={deleteHandleClick}
            >
              <img src="/icons2/crossed large, close.svg" alt="delete"></img>
            </Button>
          )} */}
          </div>
          {/* {isFocus || isMobile ? (
            <div className={styles.addressContainer}>
              <Button className={styles.automaticallySelectPoint} variant="transparent">
                <img alt="mePoint" src="/icons2/send, email, paper plane, arrow.svg"></img>
                <span>Определить автоматически адрес и район</span>
              </Button>
            </div>
          ) : null} */}
          <div className={styles.isMobileSelectAddress}>
            <div className={styles.searchAddressIsMobile}>
              <GeoAddress selectAddress={selectAddress} isFocus={isFocus} isMobile={isMobile} />
            </div>

            <Button
              onClick={saveDeliveryAddress}
              type="submit"
              variant="transparent"
              disabled={!state.selectedAddress || isLoading}
              className={cn(styles.buttonDeliverHere)}
            >
              {isLoading ? <Spinner /> : 'Доставить сюда'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GEO;
