import React, { useContext } from 'react';
import styles from './MobileNavigationTabs.module.scss';
import routes from '../../helpers/routes';
import { useRouter } from 'next/router';
import Button from '../Button';
import cn from 'classnames';
import { BasketContext } from '../../context/BasketContext';
import { AuthContext } from '../../context/AuthContext';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';

const MobileNavigationTabs = () => {
  const router = useRouter();

  const { data: basketData } = useContext(BasketContext);
  const authContext = useContext(AuthContext);

  let productCount;
  if (basketData?.products) {
    productCount = Object.keys(basketData.products).filter((key) => {
      return key !== 'ids';
    }).length;
  }

  const linkItems = [
    { name: 'Главная', img: 'home-icon_mobile_navigation.svg', href: routes.root },
    { name: 'Каталог', img: 'search_catalogue_mobile_navigation.svg', href: routes.products },
    {
      name: 'Корзина',
      img: 'cart_shopping-mobile_navigation.svg',
      href: routes.basket,
      productCount: productCount,
    },
    // { name: 'Избранное', img: 'favorite-mobile_navigation.svg', href: '/' },
    { name: 'Профиль', img: 'people-mobile_navigation.svg', href: routes.profile, profile: true },
  ];

  const handleClick = (e, href, profile) => {
    if (profile && !authContext.user) {
      Emmiter.emit(EVENTS.OPEN_AUTH_MODAL);
    } else {
      e.preventDefault();
      router.push(href);
    }
  };

  return (
    <div className={styles.wrapper}>
      {linkItems.map((item, index) => (
        <div className={styles.menuItems} key={index}>
          <Button
            variant="transparent"
            data={item.href}
            onClick={(e) => handleClick(e, item.href, item?.profile)}
            className={cn(styles.button, { [styles.buttonActive]: router.asPath === item.href })}
          >
            <div>
              <img
                className={cn(styles.icon, { [styles.icon2]: router.asPath === item.href })}
                alt=""
                src={`/icons2/${item.img}`}
              ></img>
              {item?.productCount && (
                <div className={styles.productCount}>{item?.productCount}</div>
              )}

              <div className={styles.text}>{item.name}</div>
            </div>
          </Button>
        </div>
      ))}
    </div>
  );
};

export default MobileNavigationTabs;
