import { useEffect } from 'react';

const useBodyOverflow = (modalOpen) => {
  useEffect(() => {
    document.body.classList.toggle('u-lock', modalOpen);

    return () => {
      document.body.classList.remove('u-lock');
    }
  }, [modalOpen]);
};

export default useBodyOverflow;
