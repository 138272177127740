import React from 'react';
import styles from './NavigationDelivery.module.scss';
import { useDeliveryInfo } from '../../../lib/useDeliveryInfo';

const NavigationDelivery = () => {
  const { delivery, openGeoModal } = useDeliveryInfo();

  return (
    <div className={styles.headerDeliveryText} onClick={openGeoModal}>
      <img src="/icons/location.svg" alt="location"></img>
      {delivery.address ? (
        <>
          <span className={styles.selfCall}>Доставка </span>{' '}
          <span className={styles.fattyText}>{delivery.district.name} район</span>
          <span className={styles.street}>{delivery.address}</span>
        </>
      ) : (
        <>Выбрать адрес доставки</>
      )}
    </div>
  );
};

export default NavigationDelivery;
