import React, { useEffect, useState, useContext } from 'react';
import Link from 'next/link';
import styles from './Navigation.module.scss';
import Button from '../Button';
import ContentLayout from '../Layout/ContentLayout/ContentLayout';
import routes, { routesPatterns } from '../../helpers/routes';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import NavigationDelivery from './NavigationDelivery/NavigationDelivery';
import NavigationUser from './NavigationUser';
import NavigationSearch from './NavigationSearch';
import { BasketContext } from '../../context/BasketContext';
import SearchMobile from '../Search/SearchMobile';
import Input from '../Input';
import { useRouter } from 'next/router';
import useBodyOverflow from '../../hooks/useBodyOverflow';
import cn from 'classnames';
import { getCookie } from '../../lib/cookie';
import { COOKIE } from '../../constants/COOKIE';
import categories from './categories.data';
import CartBadge from '../Cart/CartBadge';

let isRendered = false;

const Navigation = (props) => {
  const [searchModal, setSearchModal] = useState(false);
  const { searchInput } = props;
  const { data: basketData } = useContext(BasketContext);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const router = useRouter();

  const urlPush = (e) => {
    setInputValue(e.target.value);

    if (e.target.value !== '') {
      router.replace({
        pathname: routes.search(e.target.value),
      });
    }
  };
  const [deliveryDay, setDeliberyDay] = useState();

  const clear = () => {
    setInputValue('');
  };

  useEffect(() => {
    const delivery = getCookie(COOKIE.DELIVERY_DAY);
    setDeliberyDay(delivery);
  }, []);

  useEffect(() => {
    const handleEventt = () => {
      setMobileSearch(false);
    };

    Emmiter.on(EVENTS.OPEN_MOBILE_SEARCH_MODAL, handleEventt);

    return () => {
      Emmiter.off(EVENTS.OPEN_MOBILE_SEARCH_MODAL, handleEventt);
    };
  }, []);

  useBodyOverflow(mobileSearch);

  const mobileSearcOpen = () => {
    setMobileSearch(true);
  };

  useEffect(() => {
    let headerSticky = document.querySelector('.header');

    const isSticky = () => {
      if (window.scrollY >= 43 && !isRendered) {
        headerSticky.classList.add(`isSticky`);

        isRendered = true;
      }

      if (window.scrollY <= 43 && isRendered) {
        headerSticky.classList.remove('isSticky');

        isRendered = false;
      }
    };

    window.addEventListener('scroll', isSticky);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  let productCount;
  if (basketData?.products) {
    productCount = basketData.products.ids.length;
  }

  const closeSearchModal = () => {
    document.body.classList.remove('u-lock');
    setSearchModal(false);
  };

  const handleClickFarmerRegistration = () => {
    Emmiter.emit(EVENTS.OPEN_MODAL_FARMER_REGISTRATION);
  };

  const handleClickDeliveryDay = () => {
    Emmiter.emit(EVENTS.OPEN_MODAL_DELIVERY_DAY);
  };

  const handleClickMobileNavigation = () => {
    Emmiter.emit(EVENTS.OPEN_MOBILE_NAVIGATION);
  };

  useEffect(() => {
    const isFocus = () => {
      setSearchModal(true);

      document.body.classList.add('u-lock');
    };

    Emmiter.on(EVENTS.OPEN_POPUP, isFocus);

    return () => {
      Emmiter.off(EVENTS.OPEN_POPUP, isFocus);
    };
  }, [searchModal]);
  useBodyOverflow(mobileSearch);

  return (
    <div className={styles.wrapper}>
      <CartBadge count={productCount} />
      {searchModal ? <div className={styles.popup} onClick={closeSearchModal}></div> : null}
      <div className={cn(styles.headerWrap, 'header')}>
        {mobileSearch && <SearchMobile></SearchMobile>}
        <div className={styles.headerLocation}>
          <ContentLayout>
            <div className={styles.headerLocationFlexContainer}>
              <div className={styles.locationInformation}>
                <NavigationDelivery />
                <nav className={styles.headerMenu}>
                  <ul className={styles.headerMenuList}>
                    <li className={styles.headerMenuItem}>
                      <Link href={routesPatterns.flatpage} as={routes.flatpage('about-us')}>
                        О нас
                      </Link>
                    </li>
                    <li className={styles.headerMenuItem}>
                      {' '}
                      <Link href={routesPatterns.flatpage} as={routes.flatpage('quality-control')}>
                        Контроль качества
                      </Link>
                    </li>
                    <li className={styles.headerMenuItem}>
                      <Link href={routes.farmers}>Фермеры</Link>
                    </li>
                    <li className={styles.headerMenuItem}>
                      <Link href={routes.deliveryRegistration}>Доставка</Link>
                    </li>
                    <li className={styles.headerMenuItem} onClick={handleClickFarmerRegistration}>
                      Стать фермером
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </ContentLayout>
        </div>

        <ContentLayout>
          <div className={styles.stickyWrapper}>
            <div className={styles.stickyBackground}></div>
            <div className={styles.headerInfoBlock}>
              <div className={styles.headerInfoBlockCatalog}>
                <div className={styles.mobileItemsBlock}>
                  <a href="/" className={styles.headerLogoDesktop}>
                    <img src="/icons2/header-logotip.svg" alt="logoDesktop"></img>
                  </a>
                  <div className={styles.headerBasketicons}>
                    <div className={styles.interactionIconsBasket}>
                      {productCount ? (
                        <div className={styles.basketProductCount}>{productCount}</div>
                      ) : null}

                      <a href={routes.basket}>
                        <img src="/icons2/shopping-cart.svg" alt="Корзина"></img>
                      </a>
                    </div>

                    <div
                      className={styles.headerDropDownMenu}
                      onClick={handleClickMobileNavigation}
                    >
                      <img src="/icons2/menu.svg" alt="menu"></img>
                    </div>
                  </div>
                </div>

                <div className={styles.desktopItemsBlock}>
                  {' '}
                  <Link href={routes.products} passHref>
                    <Button
                      rounded
                      tag="a"
                      variant="dark-bluishGreen"
                      className={styles.headerCatalogDesktop}
                    >
                      <img src="/icons2/header-catalog.svg" alt="logoCatalog"></img>
                      <span className={styles.headerCatalogText}>Каталог</span>
                    </Button>
                  </Link>
                  <Button
                    rounded
                    variant="dark-bluishGreen"
                    size="sm"
                    className={styles.headerDateDeliveryDesktop}
                    onClick={handleClickDeliveryDay}
                  >
                    {deliveryDay ? <span>{deliveryDay} </span> : <span> Доставка все дни </span>}
                    <img src="/icons2/white-arrow.svg" alt="arrow"></img>
                  </Button>
                  <div className={styles.headerInfoBlockSearchBlock}>
                    <div className={styles.desktopSearch}>
                      {searchInput ? (
                        <div className={styles.headerSearchDesktop}>
                          <Input
                            simplePlaceholder="Поиск..."
                            value={inputValue}
                            onChange={urlPush}
                            search
                            valueDelete
                            onClick={clear}
                          ></Input>
                        </div>
                      ) : (
                        <NavigationSearch searchModal={searchModal}></NavigationSearch>
                      )}
                    </div>
                    <div className={styles.mobileSearch}>
                      {searchInput ? (
                        <div className={styles.headerSearchDesktop}>
                          <Input
                            simplePlaceholder="Поиск..."
                            value={inputValue}
                            onChange={urlPush}
                            search
                            valueDelete
                            onClick={clear}
                          ></Input>
                        </div>
                      ) : (
                        <Button
                          variant="white-transparent"
                          size="md"
                          className={styles.headerSearchIcon}
                          onClick={mobileSearcOpen}
                        >
                          <img src="/icons2/search-sm.svg" alt="searchIcon"></img>
                        </Button>
                      )}
                    </div>

                    <div className={styles.headerInteractionIcons}>
                      {/* <div className={styles.interactionIconsFavorite}>
              <img src="/icons2/heart.svg" alt="Избранное"></img>
            </div> */}
                      <div className={styles.interactionIconsBasket}>
                        {productCount ? (
                          <div className={styles.basketProductCount}>{productCount}</div>
                        ) : null}

                        <a href={routes.basket}>
                          <img src="/icons2/shopping-cart.svg" alt="Корзина"></img>
                        </a>
                      </div>
                      <NavigationUser />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.headerDesktopTags}>
            <nav className={styles.headerTagsMenu}>
              <ul className={styles.headerTagsMenuList}>
                <li className={styles.headerTagsMenuPercent}>
                  <Link href={routes.discounts}>
                    <a className={styles.menuLink}>
                      <img src="/icons2/percent, sales.svg" alt="discounts"></img>
                      Скидки
                      <img className={styles.divider} src="/icons2/divider.svg"></img>
                    </a>
                  </Link>
                </li>

                {categories.map((category) => (
                  <Button
                    variant="transparent"
                    className={styles.headerTagsMenuItem}
                    key={category.id}
                  >
                    <Link
                      href={routesPatterns.productsCategory}
                      as={routes.productsCategory(category.slug)}
                    >
                      {category.name}
                    </Link>
                  </Button>
                ))}
              </ul>
            </nav>
          </div>
        </ContentLayout>
      </div>
    </div>
  );
};

export default Navigation;
