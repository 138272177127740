const isStage = process.env.IS_STAGE === 'true';

const stageCategories = [
  {
    id: 10,
    name: 'Сладости',
    slug: 'sladosti',
  },
  {
    id: 8,
    name: 'Хлеб и выпечка',
    slug: 'hleb-i-vypechka',
  },
  {
    id: 2,
    name: 'Овощи, фрукты, ягоды',
    slug: 'fruits',
  },
  {
    id: 6,
    name: 'Мясо, птица, кролик',
    slug: 'myaso-ptica-krolik',
  },
  {
    id: 4,
    name: 'Молоко, яйца и молочная продукция',
    slug: 'molochnye-produkty-yajco',
  },
];

const prodCategories = [
  {
    id: 2,
    name: 'Молоко, яйца и молочная продукция',
    slug: 'moloko-yajca-i-molochnaya-produkciya',
  },
  {
    id: 1,
    name: 'Сыры',
    slug: 'syry',
  },
  {
    id: 3,
    name: 'Мясо, птица, кролик',
    slug: 'myaso-ptica-krolik',
  },
  {
    id: 5,
    name: 'Овощи, фрукты и ягоды',
    slug: 'ovoshi-frukty-i-yagody',
  },
  {
    id: 10,
    name: 'Хлеб и выпечка',
    slug: 'hleb-i-vypechka',
  },
];

export default isStage ? stageCategories : prodCategories;
