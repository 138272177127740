import api from '../lib/api';

const userSources = {
  root: 'users',
  me: 'users/me',
  checkEmail: 'users/checkEmail',

  updateInfo: async (data) => {
    const response = await api.patch('users', data);

    return response.data;
  },

  loginBusiness: async (data) => {
    const response = await api.post('users/login/business', data);

    return response.data;
  },

  getCode: async ({ phone }) => {
    const response = await api.post('users/get-code', { phone });

    return response.data;
  },

  confirmCode: async ({ phone, code }) => {
    const response = await api.post('users/confirm-code', { phone, code });

    return response.data;
  },

  emailUser: async ({ email, password }) => {
    const response = await api.post('/users/login', {
      user: {
        email,
        password,
      },
    });
    return response.data;
  },

  changeEmail: async ({ oldPassword, newPassword, confirmNewPassword }) => {
    const response = await api.post('users/change-password', {
      oldPassword,
      newPassword,
      confirmNewPassword,
    });

    return response.data;
  },

  requestPasswordReset: async ({ email }) => {
    const response = await api.post('/users/request-password-reset', {
      email,
    });

    return response.data;
  },

  resetPassword: async ({ password, passwordRepeat, token }) => {
    const response = await api.post('/users/reset-password', {
      token,
      password,
      passwordRepeat,
    });

    return response.data;
  },
};

export default userSources;
