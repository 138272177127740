import React from 'react';
import cn from 'classnames';
import styles from './Authorization.module.scss';
import Button from '../Button';
import { Field } from 'react-final-form';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import { phoneValidation } from '../../helpers/validations';
import AuthorizationSmsCode from './AuthorizationSmsCode';
import InputMask from '../FormControls/InputMask';

const PhoneForm = (props) => {
  const { handleSubmit, state, goBack } = props;

  const handleClose = () => {
    Emmiter.emit(EVENTS.CLOSE_AUTH_MODAL);
  };

  const logMail = () => {
    Emmiter.emit(EVENTS.LOG_BY_MAIL);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.authorizationContainer}>
      {state.confirmCode && (
        <Button className={styles.authorizationBackButton} variant="transparent" onClick={goBack}>
          <img alt="jackdaw" src="/icons2/jackdaw__back-authorization.svg"></img>
          <span>Назад</span>
        </Button>
      )}

      <Button
        type="button"
        className={styles.authorizationClose}
        variant="transparent"
        onClick={handleClose}
      >
        <img src="/icons2/close_authorization.svg" alt="close_authorization"></img>
      </Button>
      <div className={styles.authorizationTitle}>
        {state.confirmCode ? 'Введите код подтверждения' : ' Введите номер телефона'}
      </div>
      <div className={styles.authorizationSubTitle}>
        {state.confirmCode
          ? 'Мы отправили код на указанный вами номер телефона для авторизации на сайте'
          : 'Перед оформлением заказа необходимо авторизоваться на сайте'}
      </div>
      {state.confirmCode ? (
        <AuthorizationSmsCode />
      ) : (
        <Field name="phone" validate={phoneValidation}>
          {({ input, meta }) => (
            <InputMask
              {...input}
              meta={meta}
              placeholder="+7 (000) 000-00-00"
              className={styles.authorizationInput}
            />
          )}
        </Field>
      )}
      <Button type="submit" className={styles.authorizationGetCodeButton}>
        {state.confirmCode ? 'Продолжить' : 'Получить код'}
      </Button>
      {!state.confirmCode && (
        <Button
          variant="transparent"
          type="button"
          className={cn(styles.buttonBorder, styles.authorizationGetCodeButton)}
          onClick={logMail}
        >
          Войти по почте
        </Button>
      )}

      <div className={styles.authorizationPrivacyPolicy}>
        Нажимая кнопку «Продолжить» вы соглашаетесь с <span> политикой конфиденциальности </span>
      </div>
    </form>
  );
};

export default PhoneForm;
