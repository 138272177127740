import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import isArray from 'lodash/isArray';
import Error from '../Error';
import styles from './Select.module.scss';

const hasValue = (value) => {
  if (isArray(value)) {
    return value.length > 0;
  }

  if (Number(value)) {
    return true;
  }

  return Boolean(value);
};

const Select = (props) => {
  const {
    children,
    meta,
    defaultValue,
    value,
    onChange,
    onSelect,
    className,
    isMulti,
    disabled,
    ...rest
  } = props;

  const stickCn = cn(styles.stick, {
    [styles.stickActive]: hasValue(value) || defaultValue,
    [styles.stickError]: meta && meta.error && meta.touched,
    [styles.disabled]: disabled,
  });
  const containerCn = cn(styles.container, {
    [className]: !!className,
    [styles.error]: meta && meta.error && meta.touched,
  });

  const handleChange = (value) => {
    onChange(value);
    if (onSelect) onSelect(value);
  };

  return (
    <div className={containerCn}>
      <ReactSelect
        classNamePrefix="select"
        defaultValue={defaultValue}
        components={{ IndicatorSeparator: null }}
        onChange={handleChange}
        value={value}
        isMulti={isMulti}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        isDisabled={disabled}
        noOptionsMessage={() => 'Нет результатов'}
        placeholder={null}
        {...rest}
      />
      <div className={stickCn}>{children}</div>
      <Error meta={meta} />
    </div>
  );
};

Select.defaultProps = {
  isMulti: false,
  children: null,
  value: null,
  defaultValue: null,
  options: null,
  meta: {},
  className: null,
  disabled: false,
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  children: PropTypes.string,
  value: PropTypes.shape(),
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
