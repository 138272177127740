import { useState, useEffect } from 'react';
import { getCookie } from './cookie';
import { COOKIE } from '../constants/COOKIE';
import Emmiter from './emmiter';
import EVENTS from '../constants/EVENTS';

const useDeliveryInfo = () => {
  const [delivery, setDelivery] = useState({
    district: null,
    address: null,
  });

  useEffect(() => {
    const delivery = getCookie(COOKIE.DELIVERY_DISTRICT);
    const deliveryAddress = getCookie(COOKIE.DELIVERY_ADDRESS_NAME);

    if (delivery) {
      setDelivery({
        district: JSON.parse(delivery),
        address: deliveryAddress,
      });
    }
  }, []);

  const openGeoModal = () => {
    Emmiter.emit(EVENTS.OPEN_GEO_MODAL);
  };

  return {
    delivery,
    openGeoModal,
  };
};

export { useDeliveryInfo };
