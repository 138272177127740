import React, { useState, useEffect } from 'react';
import styles from './Navigation.module.scss';
import Input from '../Input/Input';
import searchSources from '../../sources/searchSources';
import Button from '../Button';
import cn from 'classnames';
import ProductCard from '../ProductCard';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import Link from '../Link';
import routes, { routesPatterns } from '../../helpers/routes';

let timeoutId;

const NavigationSearch = (props) => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const { searchModal } = props;

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleClick = () => {
    setQuery('');
  };

  useEffect(() => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(async () => {
      const params = { query };

      const data = await searchSources.getSearch(params);

      setSearchResults(data);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [query]);

  const isFocus = () => {
    Emmiter.emit(EVENTS.OPEN_POPUP);
  };

  return (
    <div
      className={cn(styles.headerSearchDesktop, {
        [styles.headerSearchDesktopActive]: searchModal,
      })}
    >
      <Input
        value={query}
        onChange={handleChange}
        onClick={handleClick}
        valueDelete
        onFocus={isFocus}
        whiteBack
        search
      ></Input>
      {searchModal && (
        <div className={styles.searchModalWrapper}>
          <div className={styles.searchModalBlock}>
            {/* {query.length <= 0 && (
              <div className={styles.historyBlock}>
                <div className={styles.historyButtonBlock}>
                  <div className={styles.buttonText}>История поиска</div>
                  <Button className={styles.buttonClearHistory} variant="transparent">
                    <img alt="trash_can" src="/icons2/trash_can-search_mobile.svg"></img>
                  </Button>
                </div>
                <nav className={styles.historyMenu}>
                  <ul className={styles.historyMenuList}>
                    <li className={styles.historyMenuItem}>Сыр</li>
                    <li className={styles.historyMenuItem}>Сыр</li>
                    <li className={styles.historyMenuItem}>Сыр</li>
                  </ul>
                </nav>
              </div>
            )} */}

            {/* {query.length > 0 && (
              <div className={styles.additionalTagsBlock}>
                <nav className={styles.additionalTagsMenu}>
                  <ul className={styles.additionalTagsMenuList}>
                    <li className={styles.additionalTagsMenuItem}>маринованные</li>
                    <li className={styles.additionalTagsMenuItem}>маринованные</li>
                    <li className={styles.additionalTagsMenuItem}>маринованные</li>
                    <li className={styles.additionalTagsMenuItem}>маринованные</li>
                  </ul>
                </nav>
              </div>
            )} */}

            {query.length > 0 && (
              <div className={styles.subcategoriesWrapper}>
                {searchResults.categories.map((category) => (
                  <div key={category.id}>
                    {category.subcategory.map((subcategory) => (
                      <div className={styles.subcategoriesBlock} key={subcategory.id}>
                        <Link
                          className={styles.categoryBlock}
                          href={routes.productsSubCategory(category.slug, subcategory.slug)}
                        >
                          <div className={styles.subcategoriesTitle}>{subcategory.name}</div>
                          <div className={styles.categoryTitle}>{category.name}</div>
                        </Link>
                        <Button variant="transparent" className={styles.fallInCategory}>
                          <img
                            alt="jackdaw_right"
                            src="/icons2/chevron_right-placing_order.svg"
                          ></img>
                        </Button>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {query.length > 0 && (
              <div className={styles.productWrapper}>
                {searchResults.products.map((product) => (
                  <div className={styles.ProductCardIndents} key={product.id}>
                    <ProductCard key={product.id} className={styles.card} data={product} inSlider />
                  </div>
                ))}
              </div>
            )}
          </div>
          {query && searchResults.products.length ? (
            <Link href={routesPatterns.search} as={routes.search(query)}>
              <Button className={styles.showMoreSearch}>Показать еще товары</Button>
            </Link>
          ) : null}
        </div>
      )}

      {query ? null : (
        <Button variant="transparent" className={styles.searchButton}>
          <img src="/icons2/search-sm.svg" alt="search_icon"></img>
        </Button>
      )}
    </div>
  );
};

export default NavigationSearch;
