import React from 'react';
import ReactVerificationInput from 'react-verification-input';
import Error from '../../Error';

const VerificationInput = (props) => {
  const { length = 4 } = props;

  return (
    <>
      <ReactVerificationInput
        value={props.value}
        onChange={props.onChange}
        classNames={props.className}
        length={length}
        validChars="0-9"
        inputProps={{ inputMode: 'numeric' }}
        placeholder=""
      />
      <Error meta={props.meta} />
    </>
  );
};

export default VerificationInput;
