import get from 'lodash/get';
import { PRODUCT_SIZE_TYPES_NAMES } from '../constants/PRODUCTS';

export const getProductPhoto = (data) => get(data, 'photos[0]');

export const getProductSizeValue = (price, t) =>
  price.type === 'each' ? t('each') : `${price.size}${PRODUCT_SIZE_TYPES_NAMES[price.size_type]}`;

export const getProductDiscount = (product) => {
  const { priceOld, price } = product;

  return priceOld && price < priceOld ? Math.ceil(100 - (price / priceOld) * 100) : null;
};
