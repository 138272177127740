import api from '../lib/api';

const searchSources = {
  async getSearch(params) {
    const response = await api.get('/search', { params });

    return response.data;
  },
};

export default searchSources;
