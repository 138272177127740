import api from '../lib/api';

const districtsSources = {
  districts: async () => {
    const resposne = await api.get('/districts');

    return resposne;
  },

  findByName: async (name) => {
    const resposne = await api.get(`/districts/${name}`);

    return resposne.data;
  },
};

export default districtsSources;
