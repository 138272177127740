import React, { useContext } from 'react';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import styles from './Navigation.module.scss';
import { AuthContext } from '../../context/AuthContext';
import Link from '../Link';
import routes, { businessRoutes } from '../../helpers/routes';

const NavigationUser = () => {
  const authContext = useContext(AuthContext);

  const handleClickProfile = () => {
    Emmiter.emit(EVENTS.OPEN_AUTH_MODAL);
  };

  if (!authContext.user) {
    return (
      <div className={styles.interactionUser} onClick={handleClickProfile}>
        <img src="/icons2/user.svg" alt="Профиль"></img>
      </div>
    );
  }

  return (
    <>
      <Link className={styles.interactionUser} href={routes.profile}>
        <img src="/icons2/user.svg" alt="Профиль"></img>
      </Link>
      {authContext.user.storeMember && (
        <Link className={styles.interactionUser} href={businessRoutes.orders}>
          <img src="/icons2/user-business.svg" alt="Заказы"></img>
        </Link>
      )}
    </>
  );
};

export default NavigationUser;
