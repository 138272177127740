import React, { useEffect, useState } from 'react';
import styles from './Authorization.module.scss';
import Button from '../Button';
import Input from '../Input';
import { Field } from 'react-final-form';
import { validEmail } from '../../helpers/validations';
import cn from 'classnames';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';

const MailForm = (props) => {
  const { handleSubmit, enterByMail, reset } = props;
  const [recoverEmail, setRecoverEmail] = useState(false);

  const handleClose = () => {
    Emmiter.emit(EVENTS.CLOSE_AUTH_MODAL);
  };

  const logNotMail = () => {
    Emmiter.emit(EVENTS.LOG_BY_NOT_MAIL);
    reset();
  };

  const recoverMail = () => {
    setRecoverEmail(true);
  };

  const goBackMail = () => {
    setRecoverEmail(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit} className={styles.authorizationContainer}>
      {recoverEmail && enterByMail ? (
        <>
          <Button
            className={styles.authorizationBackButton}
            variant="transparent"
            onClick={goBackMail}
          >
            <img alt="jackdaw" src="/icons2/jackdaw__back-authorization.svg"></img>
            <span>Назад</span>
          </Button>
          <Button
            type="button"
            className={styles.authorizationClose}
            variant="transparent"
            onClick={handleClose}
          >
            <img src="/icons2/close_authorization.svg" alt="close_authorization"></img>
          </Button>
          <div className={styles.authorizationTitle}>Восстановление пароля</div>
          <div className={styles.inputWrapper}>
            <Field name="requestPasswordReset" validate={validEmail}>
              {({ input, meta }) => (
                <Input {...input} meta={meta} placeholder="Ваш e-mail" type="text" />
              )}
            </Field>
          </div>
          <Button type="submit" className={styles.authorizationGetCodeButton}>
            Отправить пароль
          </Button>
        </>
      ) : (
        <>
          {enterByMail && (
            <Button
              className={styles.authorizationBackButton}
              variant="transparent"
              onClick={logNotMail}
            >
              <img alt="jackdaw" src="/icons2/jackdaw__back-authorization.svg"></img>
              <span>Назад</span>
            </Button>
          )}
          <Button
            type="button"
            className={styles.authorizationClose}
            variant="transparent"
            onClick={handleClose}
          >
            <img src="/icons2/close_authorization.svg" alt="close_authorization"></img>
          </Button>
          <div className={styles.authorizationTitle}>Войти по почте</div>
          <div className={styles.inputWrapper}>
            <Field name="email" validate={validEmail}>
              {({ input, meta }) => (
                <Input {...input} meta={meta} placeholder="Ваш e-mail" type="text" />
              )}
            </Field>
          </div>
          <div className={styles.inputWrapper}>
            <Field name="password">
              {({ input, meta }) => (
                <Input {...input} meta={meta} placeholder="Пароль" type="password" />
              )}
            </Field>
          </div>
          <Button type="submit" className={styles.authorizationGetCodeButton}>
            Войти
          </Button>
          <Button
            type="button"
            variant="transparent"
            onClick={recoverMail}
            className={cn(styles.authorizationGetCodeButton, styles.buttonBorder)}
          >
            Восстановить пароль
          </Button>
        </>
      )}
    </form>
  );
};

export default MailForm;
