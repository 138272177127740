import React, { useEffect, useState } from 'react';
import styles from './ModalDeliveryDay.module.scss';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import Button from '../Button';
import useBodyOverflow from '../../hooks/useBodyOverflow';
import { Form, Field } from 'react-final-form';
import Select from '../Select';
import { removeCookie, setCookie } from '../../lib/cookie';
import { COOKIE } from '../../constants/COOKIE';
import { getWeekDaysArray } from '../../helpers/formatters/date';

const ModalDeliveryDay = () => {
  const dates = getWeekDaysArray();

  const datesArray = [
    {
      id: 'all',
      name: 'Все дни доставки',
    },
    ...dates,
  ];

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const handleEvent = () => {
      setModalShow(true);
    };

    Emmiter.on(EVENTS.OPEN_MODAL_DELIVERY_DAY, handleEvent);

    return () => {
      Emmiter.off(EVENTS.OPEN_MODAL_DELIVERY_DAY, handleEvent);
    };
  }, []);

  const handleClickCloseModal = () => {
    setModalShow(false);
  };

  const handleSubmit = (values) => {
    if (values.date.id === 'all') {
      removeCookie(COOKIE.DELIVERY_DAY);
      removeCookie(COOKIE.DELIVERY_FILTER);
    } else {
      setCookie(COOKIE.DELIVERY_DAY, values.date.name);
      setCookie(COOKIE.DELIVERY_FILTER, values.date.data);
    }

    window.location.reload();
  };

  useBodyOverflow(modalShow);

  if (!modalShow) return null;

  return (
    <>
      <div className={styles.popupMenu} onClick={handleClickCloseModal}></div>
      <div className={styles.wrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.headerBlock}>
            <div className={styles.title}>ДЕНЬ ДОСТАВКИ</div>
            <Button
              className={styles.closedModalDelivery}
              variant="transparent"
              onClick={handleClickCloseModal}
            >
              <img src="/icons2/close-modal_delivery.svg"></img>
            </Button>
          </div>
          {/* <div className={styles.deliveryLocationContainer}>
            <div className={styles.deliveryLocationLeftBlcok}>
              <img
                className={styles.locationDelivery}
                src="/icons2/pin_location-modal_delivery.svg"
                alt="location"
              ></img>
              <div className={styles.deliveryLocationText}>
                Самовывоз в <span>Тверском районе </span>
                <p> ул. Романов переулок, д. 2/6</p>
              </div>
            </div>
            <Button className={styles.changeButton} variant="transparent">
              <img src="/icons2/edit_pencil-modal_delivery.svg" alt="pencil"></img>
            </Button>
          </div> */}

          <div className={styles.selectionDeliveryDayTitle}>
            Укажите нужный день доставки для актуализации ассортимента под конкретный день недели
          </div>
          <div className={styles.selectionDeliveryDay}>
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <Field name="date">
                    {({ input, meta }) => (
                      <div>
                        <Select
                          options={datesArray}
                          placeholder="День поставки"
                          meta={meta}
                          {...input}
                        ></Select>
                      </div>
                    )}
                  </Field>
                  <div className={styles.catalogButtonWrapper}>
                    <Button
                      className={styles.catalogButton}
                      variant="transparent"
                      type="submit"
                      disabled={!values.date}
                    >
                      Актуализировать каталог
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalDeliveryDay;
