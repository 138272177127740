import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import plural from 'plural-ru';
import Link from '../../Link';
import styles from './CartBadge.module.scss';
import routes from '../../../helpers/routes';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import { BasketContext } from '../../../context/BasketContext';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';

let runTimeout = false;

const CardBadge = ({ count }) => {
  const { productsPrice, data: basketData } = useContext(BasketContext);
  const router = useRouter();
  const includesBasket = router.asPath.includes('basket');

  const [visible, setVisible] = useState(!isEmpty(basketData) && !includesBasket);
  const visibleRef = useRef(false);

  useLayoutEffect(() => {
    if (isEmpty(basketData)) {
      runTimeout = true;

      setVisible(false);
      visibleRef.current = false;
    }

    const showBadge = () => {
      const isCartPage = window.location.pathname.includes(routes.basket);

      if (isCartPage) return;

      if (!isEmpty(basketData || productsPrice > 0)) {
        setVisible(true);
        runTimeout = false;
      }
      visibleRef.current = true;
    };

    Emmiter.on(EVENTS.ADD_TO_CART, showBadge);

    return () => {
      Emmiter.removeListener(EVENTS.ADD_TO_CART, showBadge);
    };
  }, [productsPrice, basketData?.products?.ids?.length, basketData]);

  const className = cn(styles.container, {
    [styles.visible]: visible,
  });

  return (
    <Link href={routes.basket}>
      <div className={className}>
        <img src="/bag.svg" className={styles.image} />
        <div className={styles.info}>
          <div className={styles.counts}>
            {count} <span>{plural(count, 'товар', 'товара', 'товаров')}</span>
            <span>
              <span className={styles.totalLabel}>Сумма:</span> {productsPrice}₽
            </span>
          </div>
          <div className={styles.proceed}>Оплатить</div>
        </div>
      </div>
    </Link>
  );
};

export default CardBadge;
