import React, { useState, useEffect } from 'react';
import routes, { routesPatterns } from '../../../helpers/routes';
import searchSources from '../../../sources/searchSources';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import ProductCard from '../../ProductCard';
import Input from '../../Input/Input';
import Button from '../../Button';
import Link from '../../Link';
import styles from './SearchMobile.module.scss';

let timeoutId = null;

const SearchMobile = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  const mobileSearchClose = () => {
    Emmiter.emit(EVENTS.OPEN_MOBILE_SEARCH_MODAL);
  };

  const clearInput = () => {
    setQuery('');
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(async () => {
      const params = { query };

      const data = await searchSources.getSearch(params);

      setSearchResults(data);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [query]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputBlock}>
        {query.length > 0 && (
          <Button className={styles.buttonBack} variant="transparent" onClick={clearInput}>
            <img alt="jackdaw_right" src="/icons2/chevron-right-small.svg"></img>
          </Button>
        )}

        <Input
          value={query}
          onChange={handleChange}
          search
          valueDelete
          onClick={clearInput}
        ></Input>
        <Button className={styles.cancelButton} variant="transparent" onClick={mobileSearchClose}>
          отмена
        </Button>
      </div>

      {/* {query.length <= 0 && (
        <div className={styles.historyBlock}>
          <div className={styles.historyButtonBlock}>
            <div className={styles.buttonText}>История</div>
            <Button className={styles.buttonClearHistory} variant="transparent">
              <img alt="trash_can" src="/icons2/trash_can-search_mobile.svg"></img>
            </Button>
          </div>
          <nav className={styles.historyMenu}>
            <ul className={styles.historyMenuList}>
              <li className={styles.historyMenuItem}>Сыр</li>
              <li className={styles.historyMenuItem}>Сыр</li>
              <li className={styles.historyMenuItem}>Сыр</li>
            </ul>
          </nav>
        </div>
      )}

      {query.length > 0 && (
        <div className={styles.additionalTagsBlock}>
          <nav className={styles.additionalTagsMenu}>
            <ul className={styles.additionalTagsMenuList}>
              <li className={styles.additionalTagsMenuItem}>маринованные</li>
              <li className={styles.additionalTagsMenuItem}>маринованные</li>
              <li className={styles.additionalTagsMenuItem}>маринованные</li>
              <li className={styles.additionalTagsMenuItem}>маринованные</li>
            </ul>
          </nav>
        </div>
      )} */}

      {query.length > 0 && (
        <div className={styles.subcategoriesWrapper}>
          {searchResults.categories.map((category) => (
            <div key={category.id}>
              {category.subcategory.map((product) => (
                <Link
                  className={styles.categoryBlock}
                  href={routes.productsSubCategory(category.slug, product.slug)}
                  key={product.id}
                >
                  {' '}
                  <div className={styles.subcategoriesBlock}>
                    <div className={styles.categoryBlock}>
                      <div className={styles.subcategoriesTitle}>{product.name}</div>
                      <div className={styles.categoryTitle}>{category.name}</div>
                    </div>
                    <Button variant="transparent" className={styles.fallInCategory}>
                      <img alt="jackdaw_right" src="/icons2/chevron_right-placing_order.svg"></img>
                    </Button>
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      )}

      {query.length > 0 && (
        <div className={styles.productWrapper}>
          {searchResults.products.map((product) => (
            <div className={styles.ProductCardIndents} key={product.id}>
              <ProductCard className={styles.card} data={product} inSlider />
            </div>
          ))}
        </div>
      )}
      {query && searchResults.products.length ? (
        <Link href={routesPatterns.search} as={routes.search(query)}>
          <Button className={styles.showMoreSearch}>Показать еще товары</Button>
        </Link>
      ) : null}
    </div>
  );
};

export default SearchMobile;
