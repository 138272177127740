import React, { useState, useEffect } from 'react';
import styles from './Authorization.module.scss';
import Emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';
import { Form } from 'react-final-form';
import userSources from '../../sources/userSources';
import { NotificationManager } from 'react-notifications';
import { setUserCookie } from '../../lib/cookie';
import { useRouter } from 'next/router';
import { ROLES } from '../../constants/ROLES';
import STORAGE from '../../constants/STORAGE';
import routes, { businessRoutes } from '../../helpers/routes';
import MailForm from './MailForm';
import PhoneForm from './PhoneForm';
import useBodyOverflow from '../../hooks/useBodyOverflow';

const Authorization = () => {
  const [isOpen, toggleModal] = useState(false);
  const [state, setState] = useState({
    confirmCode: false,
    phone: null,
  });
  const router = useRouter();
  const [enterByMail, setEnterByMail] = useState(false);

  useEffect(() => {
    const openModal = () => {
      toggleModal(true);
    };

    const logMail = () => {
      setEnterByMail(true);
    };
    const logNotMail = () => {
      setEnterByMail(false);
    };
    const handleClose = () => {
      toggleModal(false);
      if (router.query.login) {
        router.replace(router.pathname);
      }
    };
    Emmiter.on(EVENTS.OPEN_AUTH_MODAL, openModal);
    Emmiter.on(EVENTS.LOG_BY_NOT_MAIL, logNotMail);
    Emmiter.on(EVENTS.CLOSE_AUTH_MODAL, handleClose);
    Emmiter.on(EVENTS.LOG_BY_MAIL, logMail);

    return () => {
      Emmiter.off(EVENTS.OPEN_AUTH_MODAL, openModal);
      Emmiter.off(EVENTS.LOG_BY_MAIL, logMail);
      Emmiter.off(EVENTS.LOG_BY_NOT_MAIL, logNotMail);
      Emmiter.off(EVENTS.CLOSE_AUTH_MODAL, handleClose);
    };
  }, []);

  const handleClose = () => {
    toggleModal(false);
    if (router.query.login) {
      router.replace(router.pathname);
    }
  };

  const onSuccessLogin = (user) => {
    const { token, ...rest } = user;

    setUserCookie('token', token);
    setUserCookie('user', JSON.stringify(rest));
    Emmiter.emit(EVENTS.SET_USER, rest);

    const redirect = window.localStorage.getItem(STORAGE.CART_REDIRECT);

    if (redirect) {
      window.localStorage.removeItem(STORAGE.CART_REDIRECT);
      return router.push(routes.cart);
    }

    if (user.storeMember && user.storeMember.role === ROLES.COURIER) {
      return router.push(businessRoutes.orders);
    }

    if (
      (user.storeMember && user.storeMember.role === ROLES.MANAGER) ||
      (user.storeMember && user.storeMember.role === ROLES.OWNER)
    ) {
      return router.push(businessRoutes.stores);
    }

    window.location.reload();
  };

  const handleSubmitAuth = async (data) => {
    try {
      if (state.confirmCode) {
        const user = await userSources.confirmCode({ code: data.confirmCode, phone: state.phone });

        return onSuccessLogin(user);
      }

      if (data.phone) {
        await userSources.getCode({ phone: data.phone });
        setState({
          confirmCode: true,
          phone: data.phone,
        });
      }

      if (data.requestPasswordReset) {
        await userSources.requestPasswordReset({
          email: data.requestPasswordReset,
        });
        NotificationManager.success(
          ' Пожалуйста, проверьте вашу почту и следуйте инструкциям для завершения процесса.',
        );
        Emmiter.emit(EVENTS.CLOSE_AUTH_MODAL);

        return;
      }

      if (data.email && data.password) {
        const user = await userSources.emailUser({ email: data.email, password: data.password });

        return onSuccessLogin(user);
      }
    } catch (error) {
      const { message } = error.response.data;
      if (message) {
        NotificationManager.error(`Ошибка: ${message}`);
      } else {
        NotificationManager.error('Ошибка. Обратитесь в службу поддержки.');
      }
    }
  };

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      confirmCode: false,
    }));
  };
  useBodyOverflow(isOpen);
  useEffect(() => {
    if (router.query.login === 'true') {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isOpen || router.query.login == 'true') {
        document.body.style.overflow = 'auto';
      }
    };
  }, [router.query.login]);

  return (
    <>
      {isOpen || router.query.login == 'true' ? (
        <div>
          <div className={styles.popupMenu} onClick={handleClose}></div>
          <div className={styles.authorizationWrapper}>
            <Form
              onSubmit={handleSubmitAuth}
              render={({ handleSubmit, form }) => {
                return (
                  <>
                    {enterByMail ? (
                      <MailForm
                        handleSubmit={handleSubmit}
                        enterByMail
                        reset={form.reset}
                      ></MailForm>
                    ) : (
                      <PhoneForm
                        state={state}
                        handleSubmit={handleSubmit}
                        goBack={goBack}
                      ></PhoneForm>
                    )}
                  </>
                );
              }}
            ></Form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Authorization;
