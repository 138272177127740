import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
// import FavoriteIconSm from '../../../public/icons2/in-favorites_sm.svg';
import BasketIconSm from '../../../public/icons2/black-basket_sm.svg';
import PlusIcon from '../../../public/icons2/pluss_lg.svg';
import MinusIcon from '../../../public/icons2/minus_lg.svg';
import Button from '../Button';
import Link from '../Link';
import styles from './ProductCard.module.scss';
import routes, { routesPatterns } from '../../helpers/routes';
import { BasketContext } from '../../context/BasketContext';
import head from 'lodash/head';
import { PRODUCT_UNITS_NAMES } from '../../constants/PRODUCTS';
import { getProductDiscount } from '../../helpers/product';
import { formatPrice } from '../../helpers/prices';
import emmiter from '../../lib/emmiter';
import EVENTS from '../../constants/EVENTS';

const ProductCard = (props) => {
  const { data } = props;
  const weekDate = {
    пн: data.deliveryMonday,
    вт: data.deliveryTuesday,
    ср: data.deliveryWednesday,
    чт: data.deliveryThursday,
    пт: data.deliveryFriday,
    сб: data.deliverySaturday,
    вс: data.deliverySunda,
  };

  const firstImg = head(data.photos);

  const { updateBasketProduct, data: basketData } = useContext(BasketContext);
  const [countProductsAdded, setCountProductsAdded] = useState();

  useEffect(() => {
    const initialCounts = {};
    basketData?.products?.ids.forEach((product) => {
      initialCounts[product] = basketData.products[product].count;
    });
    setCountProductsAdded(initialCounts);
  }, [basketData?.products]);

  const handleAddProduct = async () => {
    if (countProductsAdded[data.id] >= 1) {
      incrementProductCount();
    } else {
      await updateBasketProduct(data, 1);
      emmiter.emit(EVENTS.ADD_TO_CART);
    }
  };

  const incrementProductCount = async () => {
    await updateBasketProduct(data, basketData?.products[data.id]?.count + 1);
    emmiter.emit(EVENTS.ADD_TO_CART);
    setCountProductsAdded((prevCounts) => ({
      ...prevCounts,
      [data.id]: prevCounts[data.id] + 1,
    }));
  };

  const decrementProductCount = async () => {
    const productCountDecrement = basketData?.products[data.id]?.count - 1;

    await updateBasketProduct(data, productCountDecrement);
    emmiter.emit(EVENTS.ADD_TO_CART);
    setCountProductsAdded((prevCounts) => ({
      ...prevCounts,
      [data.id]: prevCounts[data.id] - 1,
    }));
  };

  const discount = getProductDiscount(data);

  return (
    <div className={styles.card}>
      <div
        className={cn(styles.productCardTopBlock, {
          [styles.activeBackground]: data.quantity_selection,
        })}
      >
        {basketData?.products && basketData.products[data.id] ? (
          <div className={styles.popup}></div>
        ) : null}

        <div>
          {firstImg && (
            <picture>
              <source srcset={firstImg.webp} type="image/webp"></source>
              <source srcset={firstImg.source} type="image/jpeg"></source>
              <img src={firstImg.source} className={styles.productImg} alt={data.name}></img>
            </picture>
          )}
          {!firstImg && process.env.NODE_ENV === 'development' && (
            <picture>
              <img
                src="/backup-product-dev-image.jpg"
                className={styles.productImg}
                alt={data.name}
              ></img>
            </picture>
          )}
          <div className={styles.productCardTags}>
            <div className={styles.productCardTagsInfo}>
              {data.rating !== '0' ? (
                <div className={styles.productCardReviews}>{data.rating}</div>
              ) : null}

              {data.priceOld ? (
                <div className={styles.productCardDiscounts}>-{Math.ceil(discount)}%</div>
              ) : null}
            </div>
            {/* <div className={styles.productCardToFavorites}>
            <Button size="icon" className={styles.favoriteButton} variant="favorite-sm">
              <FavoriteIconSm className={cn(styles.iconSm, 'redFavoriteIcon')} />
            </Button>
          </div> */}
          </div>
        </div>

        <Link
          as={routes.product(data.category?.slug, data.subcategory?.slug, data.id)}
          href={routesPatterns.product}
        >
          <div className={styles.productCardDaysOfWeek}>
            {Object.keys(weekDate).map((day) => (
              <div
                key={day}
                className={`${styles.dayOfWeek} ${weekDate[day] ? styles.activeDay : ''}`}
              >
                {day}
              </div>
            ))}
          </div>
        </Link>
        {basketData && basketData.products && basketData.products[data.id] ? (
          <div className={styles.productCardQuantitySelection}>
            <Button variant="favorite-sm">
              <MinusIcon className={styles.productCardMinusIcon} onClick={decrementProductCount} />
            </Button>
            <div className={cn(styles.quantitySelectionText, 'quantity-selection-text')}>
              {basketData.products[data.id].count * basketData.products[data.id].unitValue}
              <span className={styles.unitType}>
                {PRODUCT_UNITS_NAMES[basketData.products[data.id].unitType]}
              </span>
            </div>
            <Button variant="favorite-sm" onClick={incrementProductCount}>
              <PlusIcon />
            </Button>
          </div>
        ) : null}
      </div>

      <div className={styles.productCardBottomBlock}>
        <div className={cn(styles.productCart__itemTop, 'product-cart__item-top')}>
          <Link
            as={routes.product(data.category?.slug, data.subcategory?.slug, data.id)}
            href={routesPatterns.product}
            className={cn(styles.productCartTitle, 'product-cart-title')}
          >
            {data.name}
          </Link>
          <div className={styles.productCartGrammar}>
            {data.unitValue} {PRODUCT_UNITS_NAMES[data.unitType]}
          </div>
        </div>

        <div className={styles.productCardPricePurchase}>
          {data.priceOld > 0 && (
            <div className={cn(styles.productCartPastPrice, 'product-cart-past-price')}>
              {data.priceOld && (
                <>
                  <span className={styles.productPastPriceText}>{formatPrice(data.priceOld)}</span>

                  <span className={styles.productPastPriceRuble}>₽</span>
                </>
              )}
            </div>
          )}

          <div
            className={cn(styles.productCardPurchase, {
              [styles.priceWithoutDiscount]: !data.priceOld,
            })}
          >
            <div
              className={cn({
                [styles.productCardDiscountPrice]: data.priceOld,
              })}
            >
              {formatPrice(data.price)}
              <span>₽</span>
            </div>
            <Button
              size="iconXs"
              className={styles.productCardBasketButtonSm}
              onClick={handleAddProduct}
            >
              <BasketIconSm className={styles.BasketButtonIcons} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
